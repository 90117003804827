<template>
  <div class="mr-4">
    <v-subheader class="subheaderJob my-7"> Message Details </v-subheader>
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="jobInfo.titleTh"
          label="Title TH"
          maxlength="150"
          counter
          dense
          outlined
          required
        />
        <v-textarea
          v-model="jobInfo.messageTh"
          outlined
          dense
          label="Message TH"
          maxlength="250"
          counter
        />
        <v-text-field
          label="Applinks for dtac lite TH"
          v-model="jobInfo.deepLinkLiteTh"
          dense
          outlined
          required
        />
        <v-file-input
          v-model="imageDataTh"
          color="blue darken-3"
          counter
          label="Upload Image"
          prepend-icon="mdi-message-image-outline"
          clearable
          outlined
          dense
          accept="image/*"
          v-show="showLoadImage"
          :show-size="1000"
        >
          <template #selection="{ index, text }">
            <v-chip v-if="index < 2" color="blue darken-3" dark label small>
              {{ text }}
            </v-chip>

            <span v-else-if="index === 2" class="overline grey--text text--darken-3 mx-2">
              +{{ imageDataTh.length - 2 }} File(s)
            </span>
          </template>
        </v-file-input>
        <v-text-field
          label="Value Image TH"
          v-model="jobInfo.optionValueTh"
          :rules="[rules.validateURL]"
          v-show="showLoadImage"
          dense
          outlined
          required
        />
        <v-img
          class="my-3"
          v-show="jobInfo.imageIdLiteTh"
          :src="jobInfo.imageIdLiteTh"
          height="150"
          aspect-ratio="1.9"
          contain
        ></v-img>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="jobInfo.titleEn"
          label="Title EN"
          maxlength="150"
          counter
          dense
          outlined
          required
        />
        <v-textarea
          v-model="jobInfo.messageEn"
          outlined
          dense
          label="Message EN"
          maxlength="250"
          counter
        />
        <v-text-field
          label="Applinks for dtac lite EN"
          v-model="jobInfo.deepLinkLiteEn"
          dense
          outlined
          required
          :rules="[rules.ValIsNotNull]"
        />
        <v-file-input
          v-model="imageData"
          color="blue darken-3"
          counter
          label="Upload Image"
          prepend-icon="mdi-message-image-outline"
          clearable
          outlined
          dense
          accept="image/*"
          v-show="showLoadImage"
          :show-size="1000"
        >
          <template #selection="{ index, text }">
            <v-chip v-if="index < 2" color="blue darken-3" dark label small>
              {{ text }}
            </v-chip>

            <span v-else-if="index === 2" class="overline grey--text text--darken-3 mx-2">
              +{{ imageData.length - 2 }} File(s)
            </span>
          </template>
        </v-file-input>
        <v-text-field
          label="Value Image EN"
          v-model="jobInfo.optionValue"
          :rules="[rules.validateURL]"
          v-show="showLoadImage"
          dense
          outlined
          required
        />
        <v-img
          v-show="jobInfo.imageId"
          :src="jobInfo.imageId"
          height="150"
          aspect-ratio="1.9"
          contain
        ></v-img>
      </v-col>

      <v-col cols="4">
        <v-select
          label="Category"
          :items="categories.categories"
          item-text="name"
          item-value="_id"
          v-model="jobInfo.category"
          outlined
          dense
        />
        <v-select
          label="Content Type"
          :items="contentTypes.content_types"
          item-text="name"
          item-value="_id"
          v-model="jobInfo.contentType"
          outlined
          dense
        />
        <v-btn @click="onUpload" outlined small v-show="showLoadImage">Upload</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-text-field label="Deep Link Native" v-model="jobInfo.deepLink" dense outlined required />

        <v-subheader class="subheaderJob"> Options </v-subheader>
        <v-row>
          <v-col cols="6">
            <v-select
              label="Keys"
              :items="optionKeys.option_keys"
              item-text="name"
              item-value="_id"
              v-model="jobInfo.optionKey"
              outlined
              dense
            />
            <v-select
              label="Time Delay (min)"
              :items="delayTime"
              v-model="jobInfo.delay"
              outlined
              dense
            />
            <v-checkbox
              v-model="jobInfo.allowOverridePushMessageTime"
              label="Allow Override Push Message Time Limit"
              dense
            ></v-checkbox>
            <v-select
              label="Platform Selection"
              :items="deviceMode"
              item-text="Name"
              item-value="Value"
              v-model="jobInfo.device"
              outlined
              dense
            />
          </v-col>
          <v-col cols="6">
            <!-- <v-text-field
              label="Value"
              v-model="jobInfo.optionValue"
              dense
              outlined
              required /> -->
            <v-select
              label="Sound"
              :items="sounds.sounds"
              item-text="name"
              item-value="_id"
              v-model="jobInfo.sound"
              outlined
              dense
            />
            <div class="my-16"></div>
            <v-checkbox
              v-model="jobInfo.allowOverrideQuotaLimitPerDay"
              label="Allow Override Quota Limit Per Day"
              dense
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <!-- <v-row
          class="d-flex align-start px-2">
          <v-img 
            :src="jobInfo.imageId"
            aspect-ratio="1.7"
            contain></v-img>
        </v-row> -->

        <!-- <v-row 
          class="d-flex align-end ma-2">
          <v-text-field
            label="Maximum Push Per Job"
            v-model.number="jobInfo.maxPushPerJob"
            hint="Option for Job Load List and Custom Conditions"
            persistent-hint
            dense
            outlined
            required />
        </v-row> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import firebase from 'firebase';
// import { fb } from '../../helpers/firestoreDb'

export default {
  name: 'JobDetail',
  props: {
    jobInfo: {
      type: Object,
    },
  },
  data: () => ({
    delayTime: [10, 15, 20, 30, 45, 60],
    capion: '',
    picture: '',
    imageData: null,
    imageDataTh: null,
    uploadValue: 0,
    uploadValueTh: 0,
    showLoadImage: false,
    deviceMode: [
      { Name: 'iOS (app only)', Value: 'ios' },
      { Name: 'Android (app only)', Value: 'android' },
      { Name: 'Native', Value: 'native' },
      { Name: 'Web', Value: 'web' },
      { Name: 'All', Value: 'all' },
    ],
    rules: {
      validateURL: (value) => {
        const pattern =
          /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/;

        return pattern.test(value) || 'Please, check image url.';
      },
      ValIsNotNull: (val) => {
        const checkNull = val === undefined || val === null || val === '';

        if (checkNull) {
          return 'Please provide information.';
        }

        return checkNull;
      },
    },
  }),
  computed: {
    ...mapGetters({
      sounds: 'masterData/GET_SOUNDS',
      categories: 'masterData/GET_CATEGORIES',
      optionKeys: 'masterData/GET_DATAKEYS',
      contentTypes: 'masterData/GET_CONTENTTYPES',
    }),
  },
  async created() {
    await this.$store.dispatch('masterData/GET_SOUNDS_ALL');
    await this.$store.dispatch('masterData/GET_CATEGORIES_ALL');
    await this.$store.dispatch('masterData/GET_OPTIONKEYS_ALL');
    await this.$store.dispatch('masterData/GET_CONTENTTYPES_ALL');

    const categoryDefault = this.categories.categories.filter((c) => c.isDefault === true);
    const contentTypeDefault = this.contentTypes.content_types.filter((c) => c.isDefault === true);
    const optionKeyDefault = this.optionKeys.option_keys.filter((c) => c.isDefault === true);
    const soundDefault = this.sounds.sounds.filter((c) => c.isDefault === true);

    this.jobInfo.category = categoryDefault.length === 0 ? '' : categoryDefault[0]._id;
    this.jobInfo.contentType = contentTypeDefault === 0 ? '' : contentTypeDefault[0]._id;
    this.jobInfo.optionKey = optionKeyDefault.length === 0 ? '' : optionKeyDefault[0]._id;
    this.jobInfo.sound = soundDefault.length === 0 ? '' : soundDefault[0]._id;
  },

  watch: {
    'jobInfo.contentType': function (val) {
      const type = this.contentTypes.content_types.filter((t) => {
        if (t._id === val && t.name === 'text-image') {
          return t;
        }
        return null;
      });

      if (type.length !== 0) {
        this.showLoadImage = true;

        const keys = this.optionKeys.option_keys.filter((c) => c.name === 'link_picture');

        this.jobInfo.optionKey = keys.length === 0 ? '' : keys[0]._id;
      } else {
        this.showLoadImage = false;
        this.jobInfo.optionKey = '';
      }
    },

    'jobInfo.optionValue': function (val) {
      console.log('jobInfo.optionValue', val);
      if (val !== '') {
        const optionValue = this.optionKeys.option_keys.filter(
          (t) => t._id === this.jobInfo.optionKey
        );

        if (optionValue[0].name === 'link_picture') {
          this.jobInfo.imageId = val;
        }
      } else {
        this.jobInfo.imageId = '';
      }
    },

    'jobInfo.optionValueTh': function (val) {
      console.log('jobInfo.optionValueTH', val);
      if (val !== '') {
        const optionValue = this.optionKeys.option_keys.filter(
          (t) => t._id === this.jobInfo.optionKey
        );

        if (optionValue[0].name === 'link_picture') {
          this.jobInfo.imageIdLiteTh = val;
        }
      } else {
        this.jobInfo.imageIdLiteTh = '';
      }
    },

    'jobInfo.imageId': function (val) {
      this.jobInfo.optionValue = val;
    },

    'jobInfo.imageIdLiteTh': function (val) {
      this.jobInfo.optionValueTh = val;
    },
  },

  methods: {
    create() {
      // const post = {
      //   photo: this.img1
      // }

      // fb.database().ref('msgdtac-default-rtdb').push(post)
      //   .then((response) => {
      //     console.log(response)
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   });

      this.onUpload();
    },

    click1() {
      this.$refs.input1.click();
    },

    onUpload() {
      this.picture = null;
      const storageRef = firebase.storage().ref(`${this.imageData.name}`).put(this.imageData);
      const storageRefTh = firebase.storage().ref(`${this.imageDataTh.name}`).put(this.imageDataTh);

      storageRefTh.on(
        'state_changed',
        (snapshot) => {
          this.uploadValueTh = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValueTh = 100;
          storageRefTh.snapshot.ref.getDownloadURL().then((url) => {
            console.log('storageRefTh UTL', url);
            this.uploadValueTh = url;
            this.jobInfo.imageIdLiteTh = url;
          });
        }
      );

      storageRef.on(
        'state_changed',
        (snapshot) => {
          this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            console.log('storageRef UTL', url);
            this.uploadValue = url;
            this.jobInfo.imageId = url;
          });
        }
      );
    },
  },
};
</script>
